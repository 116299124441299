import { graphql, useStaticQuery } from "gatsby"

type UsePlayerCardProps = {
	allPlayerCardJson: {
		nodes: {
			jsonId: string
			vertical?: boolean
			sub?: boolean
			tool?: boolean
			link?: string
			source?: string
			twitter?: string
			canvas?: {
				w: number
				h: number
			}
			modal?: {
				clickArea: number[]
				baseSize: number
				settingType: string
				field: {
					type: string
					preDraw?: boolean
					area?: number[]
					label: string
					row?: number[]
					items?: {
						pos: number[]
						label: string
					}[][]
				}[]
			}[]
			custom?: {
				type: string
				ref: string
				refType: string
				values: number[]
				list: string[]
			}
		}[]
	}
}

const usePlayerCard = () => {
	const data = useStaticQuery<UsePlayerCardProps>(graphql`{
		allPlayerCardJson {
			nodes {
				jsonId
				vertical
				sub
				tool
				link
				source
				twitter
				canvas {
					w
					h
				}
				modal {
					clickArea
					baseSize
					settingType
					field {
						type
						preDraw
						area
						label
						row
						items {
							pos
							label
						}
					}
				}
				custom {
					type
					ref
					refType
					values
					list
				}
			}
		}
	}`)
	return data.allPlayerCardJson.nodes.map(e => Object.assign(e,
		{"id":(e.jsonId +
			(e.vertical ? `-vertical` : ``) +
			(e.sub ? `-`+ e.sub : ``)
		)}
	))
}

export default usePlayerCard

export const usePlayerCardTool = () => {
	return usePlayerCard().filter(e => e.tool == true)
}
