/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { jsx, Flex } from "theme-ui"

import Fa, { FaFont } from "./fa"
import ExternalLink from "./external-link"

type UploadImageProps = {
	sxProp: any
	height: number[]
	onChange: any
	children?: React.ReactNode
	removeIcon?: boolean
	t?: any
}

const UploadImage = ({
	sxProp, height, onChange, children, removeIcon, t
}: UploadImageProps) => {
	const [fileUrl, setFileUrl] = useState(``)
		, upload = async(e) => {
			const url = URL.createObjectURL(e.target.files[0])
			setFileUrl(url)
			onChange(url)
			e.target.value = ``
		}
		, remove = () => {
			setFileUrl(``)
			onChange(``)
		}
	return (<>
		{removeIcon && (<div sx={{textAlign: `right`,}}>
			<span onClick={remove} sx={{cursor: `pointer`,}}>
				<FaFont i="circle-xmark" color="red" />
			</span>
		</div>)}
		<Flex sx={{
			justifyContent: children ? `normal` : `center`,
		}}>
			<label sx={{
				display: `flex`,
				lineHeight: 0,
				justifyContent: `center`,
				alignItems: `center`,
				width: height,
				height: height,
				position: `relative`,
				...sxProp
			}}>
				{fileUrl != `` ?
					<img src={fileUrl} sx={{maxHeight: height, m: 0,}} />
				:	<Fa i="image" color="text" />}
				{!children && (<div sx={{
					position: `absolute`,
					top: `50%`, left: `50%`,
					transform: `translate(-50%, -50%)`,
					py: `1em`,
					px: `.5em`,
					background: `bg2`,
					border: `solid 2px`,
					borderColor: `border`,
					borderRadius: `1em`,
				}}>{t(`Click`)}</div>)}
				<input type="file" onChange={upload} sx={{display: `none`,}} />
			</label>
			{children && (<div sx={{my: `auto`, ml: [3,`24px`]}}>
				{children}
			</div>)}
		</Flex>
	</>)
}

export default UploadImage

type UpTplNagaProps = {
	t: any
	onChange: any
	link: string
}

export const UpTplNaga = ({t, onChange, link}: UpTplNagaProps) => (
	<UploadImage height={[`76px`,`106px`]} onChange={onChange} >
		① <ExternalLink href={link}>{t(`Get Template Here`)}</ExternalLink>
		<br/>
		② {t(`Click`)} <FaFont i="image" /> & {t(`Select`)}
	</UploadImage>
)

