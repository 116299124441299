/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { jsx, Flex } from "theme-ui"

import Avatar from "./avatar"
import Modal, { modalClose } from "./modal"
import useCharacters, { useList, useStar } from "../hooks/use-characters"
import useTeamBuilder from "../hooks/use-team-builder"
import useStaticImages from "../hooks/use-static-images"

type CharactersProps = {
	name: string
	t: any
	label: string
	init: string
	listName: string
	listArr: string[]
	unselect: boolean
	onChange: any
}

const Characters = ({
	name, t, label, init, listName, listArr, unselect, onChange
}: CharactersProps) => {
	const baseList = listArr ? useCharacters().filter(e => listArr.some(e2 => e2===e.name)).map(e => e.name) : useList(listName)
		, list = unselect ? [init, ...baseList] : baseList
		, [chara, setChara] = useState(init ? init : list[0])
		, set = (val) => {
			onChange(val)
			setChara(val)
		}
	useEffect(()=>{
		if(init != chara) onChange(chara)
	},[])
	return (<Flex>
		<Modal
			id={`modal-`+ name}
			label={
				<Avatar
					folder="character"
					name={chara}
					star={useStar(chara)}
				/>
			}
			sxLabel={{
				width: [`76px`,`76px`,`106px`],
			}}
			sxContent={{
				p: `4px`,
				width: [`calc(100% - 2px)`,`640px`,`760px`],
			}}
			safety={false}
		><Flex sx={{
			flexWrap: `wrap`,
		}}>
			{list.map((e,i)=>(<React.Fragment key={i}>
				<label sx={{
					width: [`calc(100% / 6)`, `calc(100% / 7)`, `calc(100% / 8)`],
					p: `4px`,
				}}>
					<input
						onChange={e => set(e.target.value)}
						type="radio"
						name={name}
						value={e}
						sx={{
							display: `none`,
						}}
					/>
					<Avatar
						onClick={modalClose}
						folder="character"
						name={e}
						star={useStar(e)}
					/>
				</label>
			</React.Fragment>))}
		</Flex></Modal>
		<div sx={{
			my: `auto`,
			ml: [3,`24px`],
		}}>
			<div sx={{mb: [1,2],}}>{t(label||`Character`)}</div>
			<div sx={{fontSize: [3,4],}}>{t(chara)}</div>
		</div>
	</Flex>)
}

export default Characters

type CharacterListProps = {
	name: string
	t: any
	init: string[]
	listName: string
	onChange: any
}

export const CharacterList = ({
	name = "characters", t, onChange,
	init = ["Lumine", "Amber", "Kaeya", "Lisa", "Barbara", "Noelle"],
	listName = "",
}: CharacterListProps) => {
	const list = useList(listName)
		, set = () => {
			const elements = document.getElementsByClassName(name)
				, arr = []
			Array.prototype.forEach.call(elements, e=>{
				if(e.checked) arr.push(e.value)
			})
			onChange(arr)
		}
	return (<Flex sx={{ flexWrap: `wrap`, }}>
		{list.map((e,i)=>(<React.Fragment key={i}>
			<label sx={{
				width: `calc(100% / 6)`,
				p: `2px`,
			}}>
				<input
					onChange={set}
					type="checkbox"
					className={name}
					value={e}
					defaultChecked={init.some(e2 => e2==e)}
					sx={{
						display: `none`,
						border: "solid 2px",
						"+ div": {
							opacity: .5,
							"> div:first-of-type": {
								border: "solid 2px",
								borderColor :"transparent",
							},
						},
						"&:checked + div": {
							opacity: 1,
							"> div:first-of-type": {
								borderColor: "yellow",
							},
						},
					}}
				/>
				<Avatar
					folder="character"
					name={e}
					star={useStar(e)}
				/>
			</label>
		</React.Fragment>))}
	</Flex>)
}
