/** @jsx jsx */
import React from "react"
import { jsx, Flex } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Img from "./image"
import { FaFont } from "./fa"
import Modal from "./modal"
import ExternalLink from "./external-link"
import { usePlayerCardTool } from "../hooks/use-player-card"

type AuthorProps = {
	cardId: string
	t: any
}

const Author = ({cardId, t}: AuthorProps) => {
	const card = usePlayerCardTool().find(e => e.id == cardId)
	return (<Flex sx={{
		justifyContent: `space-between`,
		my: [`24px`,4],
	}}>
		<div sx={{fontSize: [1,1,2], mt: `auto`,}}>{t(`Author`)}
			{card && (
				<ExternalLink
					sxProp={{ ml: `.5em`, }}
					href={`//twitter.com/`+(card.twitter ? card.twitter : card.jsonId)}
				>
					<FaFont i="twitter" color="link" />
					@{card.twitter ? card.twitter : card.jsonId}
				</ExternalLink>
			)}
		</div>
		<div><Modal
			label={<FaFont i="circle-question" color="second" />}
			sxContent={{minWidth: [`calc(100% - 2px)`,`640px`],}}
		>
			<div sx={{textAlign: `right`, fontSize: [1,1,2],}}>
				{t(`Contact`)}
				<ExternalLink
					sxProp={{ ml: `.5em`, }}
					href={`//twitter.com/LLbot_Genshin1`}
				>
					<FaFont i="twitter" color="link" />@LLbot_Genshin1
				</ExternalLink>
			</div>
			{cardId == `fukafukafuka29` ?<>
				<h3>{t(`Change Character`)}</h3>
				<p>
					{t(`To change the character, click on the character icon.`)}
				</p>
				<div sx={{my: 2,}}>
					<Img folder="player-card" name="clickcharacter" />
				</div>
			</>: cardId==`naga-ken` ?<>
				<h3>{t(`Get Template`)}</h3>
				<p>
					{t(`Click on 'Get Template Here' to download the template.`)}<br/>
					{t(`Click on the image icon to select that template.`)}
				</p>
				<div sx={{my: 2,}}>
					<Img folder="player-card" name="clickimage" />
				</div>
			</>: cardId==`naga-ken-vertical` ?<>
				<h3>{t(`Get Template`)}</h3>
				<p>
					{t(`Click on 'Get Template Here'. Then, download the image of the 'bottom of the card'.`)}<br/>
				</p>
				<div sx={{my: 2,}}>
					<Img folder="player-card" name="clickdownload" />
				</div>
				<p>
					{t(`Click on the image icon to select that template.`)}
				</p>
				<div sx={{my: 2,}}>
					<Img folder="player-card" name="clickimage" />
				</div>
			</>: null}
			<h3>{t(`Edit Card`)}</h3>
			<p>
				{t(`Click on the area of the card you wish to edit.`)}<br/>
				{t(`Enter the information according to the dialog box that appears.`)}
			</p>
			<div sx={{my: 2,}}>
				<Img folder="player-card" name="clickarea" />
			</div>
			<h3>{t(`Download Image`)}</h3>
			<p>
				{t(`If you cannot save the image in 'Download', open the image in 'New Tab' and save it.`)}
			</p>
		</Modal></div>
	</Flex>)
}

export default Author
