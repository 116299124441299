/** @jsx jsx */
import React, { useEffect, useRef } from "react"
import { jsx } from "theme-ui"

import { FaFont } from "./fa"

type DetailsProps = {
	label: string
	sxProp?: any
	children: React.ReactNode
	defaultOpen: boolean
}
/*
const Details = ({
	label, sxProp, children, height="0px"
}: DetailsProps) => {
	const e = useRef()
		, click = () => {
			if(e.current.clientHeight == e.current.scrollHeight)
				e.current.style.height = height
			else
				e.current.style.height = e.current.scrollHeight +`px`
		}
	useEffect(()=>{
		const resize = () => {
			if(e.current.style.height != height) {
				e.current.style.height = "auto"
				e.current.style.height = e.current.scrollHeight +`px`
			}
		}
		window.addEventListener('resize', resize)
		return () => {
			window.removeEventListener('resize', resize)
		}
	},[])
	
	return (<>
		<div onClick={click} sx={{
			textAlign: `center`,
			fontSize: [2,2,3],
			py: 1,
			background: `bga`,
			border: `solid 1px`,
			borderColor: `border`,
			borderRadius: `.5em`,
			cursor: `pointer`,
			...sxProp
		}}>{label}</div>
		<div ref={e} sx={{
			overflow: `hidden`,
			transition: `height 0.2s ease-out`,
		}} style={{
			height: height,
		}}>{children}</div>
	</>)
}
*/
const Details = ({
	label, sxProp, children, defaultOpen=false
}: DetailsProps) => (
	<Core
		top={true}
		label={label}
		sxProp={{
			py: 1,
			background: `bga`,
			border: `solid 1px`,
			borderColor: `border`,
			borderRadius: `.5em`,
			cursor: `pointer`,
			...sxProp
		}}
		open="plus"
		close="minus"
		defaultOpen={defaultOpen}
	>
		{children}
	</Core>
)

export default Details

export const More = ({
	label, sxProp, children, defaultOpen=false
}: DetailsProps) => (
	<Core
		top={false}
		label={label}
		sxProp={sxProp}
		open="angle-down"
		close="angle-up"
		defaultOpen={defaultOpen}
	>
		{children}
	</Core>
)

export const closeDetails = () => {
	const boxs = document.getElementsByClassName(`details-checkbox`)
		, doms = document.getElementsByClassName(`details-content`)
	for(let i=0; i<boxs.length; i++) {
		boxs[i].checked = false
		doms[i].style.height = `0px`
	}
}

const Core = ({
	top, label, sxProp, children, open, close, defaultOpen
}) => {
	const dom = useRef()
		, check = useRef()
		, onChange = () => {
			if(check.current.checked)
				dom.current.style.height = dom.current.scrollHeight +`px`
			else
				dom.current.style.height = `0px`
		}
	useEffect(()=>{
		const resize = () => {
			if(check.current?.checked) {
				dom.current.style.height = "auto"
				dom.current.style.height = dom.current.scrollHeight +`px`
			}
		}
		if(defaultOpen) setTimeout(resize, 200)//猶予200ms
		
		window.addEventListener('resize', resize)
		return () => {
			window.removeEventListener('resize', resize)
		}
	},[])
	return (<>
		{top && <CoreLabel label={label} sxProp={sxProp} check={check} onChange={onChange} open={open} close={close} defaultOpen={defaultOpen} />}
		<div ref={dom} className="details-content" sx={{
			overflow: `hidden`,
			transition: `height 0.2s ease-out`,
		}} style={{
			height: defaultOpen ? `auto` : `0px`,
		}}>{children}</div>
		{!top && <CoreLabel label={label} sxProp={sxProp} check={check} onChange={onChange} open={open} close={close} defaultOpen={defaultOpen} />}
	</>)
}

const CoreLabel = ({
	label, sxProp, check, onChange, open, close, defaultOpen
}) => (
	<label>
		<input
			ref={check} onChange={onChange}
			type="checkbox" className="details-checkbox"
			sx={{
				display: `none`,
				"& ~ *": {
					...sxProp,
					justifyContent: `center`,
					display: `none`,
					"&:first-of-type": {
						display: `flex`,
					},
					"& > *": {
						my: `auto`,
					},
				},
				"&:checked ~ *": {
					"&:first-of-type": {
						display: `none`,
					},
					"&:last-of-type": {
						display: `flex`,
					},
				},
			}}
			defaultChecked={defaultOpen}
		/>
		<div><FaFont i={open} /><div sx={{ml: `.2em`}}>{label}</div></div>
		<div><FaFont i={close} /><div sx={{ml: `.2em`}}>{label}</div></div>
	</label>
)
