/** @jsx jsx */
import * as React from "react"
import { jsx, Flex, Input, Textarea, Checkbox } from "theme-ui"

import Modal from "./modal"
import Tab from "./tab"
import UploadImage from "./upload-image"
import Field, { FieldSwitchSet, TextAlign, CheckboxField, CheckText, FrameText } from "./@player-card-field-set"
import { usePlayerCardTool } from "../hooks/use-player-card"

type ModalsProps = {
	onChange: any
	t: any
}

const Modals = ({onChange, t}: ModalsProps) => {
	const cards = usePlayerCardTool()
	return (<>{cards.map(card => (<React.Fragment key={card.id}>
	{card.modal.map((modal,i) => (<React.Fragment key={i}>
		<Modal
			//label={card.id +`-`+ i}
			id={card.id +`-`+ i}
			sxContent={{
				minWidth: [`calc(100% - 2px)`,`640px`],
			}}
		><Tab
			name={card.id +`-`+ i}
			labels={[t(`Input`),t(`Setting`)]}
		><div>
			{modal.field.map((field,j)=>(<React.Fragment key={j}>
				{(field.type==`checkbox` || field.type==`frame`) ?<>
					<div sx={{ml: 2, fontSize: [1,1,2],}}>{t(field.label)}</div>
					<div sx={{
						mb: 2,
						border: `solid 1px`,
						borderColor: `border`,
						borderRadius: `.5em`,
						p: 2,
					}}>
						{field.items.map((itemRow,k)=>(<React.Fragment key={k}><Flex>
							{itemRow.map((item,l)=>(<React.Fragment key={l}>
								<CheckboxField
									sxProp={{ml: l==0 ? 0 : 3,}}
									id={card.id +`-`+ i +`-`+ j +`-`+ k +`-`+ l}
									label={t(item.label)}
									onChange={onChange}
								/>
							</React.Fragment>))}
						</Flex></React.Fragment>))}
					</div>
				</>: field.type == `image` ?<>
					<div sx={{ml: 2, fontSize: [1,1,2],}}>{t(field.label)}</div>
					<UploadImage
						height={[`192px`, `320px`]}
						removeIcon={true}
						t={t}
						onChange={url => {
							onChange(url, card.id +`-`+ i +`-`+ j)
						}}
					/>
				</>:
					<Field label={t(field.label)} sxProp={{mb: 2,}}>
						{field.type == `textarea` ?
							<Textarea
								id={card.id +`-`+ i +`-`+ j}
								rows={field.row.length}
								onChange={onChange}
							/>
						:
							<Input
								type={field.type}
								id={card.id +`-`+ i +`-`+ j}
								onChange={onChange}
							/>
						}
					</Field>
				}
			</React.Fragment>))}
		</div><div>
			{modal.settingType == `checkbox` ?<>
				<div sx={{fontSize: [1,1,2],}}>{t(`Check Mark`)}</div>
				<CheckText
					id = {card.id +`-`+ i} t = {t}
					sxProp = {{mb:3,}}
					onChange = {onChange}
				/>
				<FieldSwitchSet
					id = {card.id +`-`+ i} t = {t}
					onChange = {onChange}
					defaultChecked = "font"
				/>
			</>: modal.settingType == `frame` ?<>
				<div sx={{fontSize: [1,1,2],}}>{t(`Frame`)}</div>
				<FrameText
					id = {card.id +`-`+ i} t = {t}
					sxProp = {{mb:3,}}
					onChange = {onChange}
				/>
				<FieldSwitchSet
					id = {card.id +`-`+ i} t = {t}
					onChange = {onChange}
					defaultChecked = "font"
				/>
			</>: modal.settingType == `image` ?
				null
			:<>
				<div sx={{fontSize: [1,1,2],}}>{t(`Text Align`)}</div>
				<TextAlign
					id = {card.id +`-`+ i} t = {t}
					sxProp = {{mb:3,}}
					onChange = {onChange}
				/>
				<FieldSwitchSet
					id = {card.id +`-`+ i} t = {t}
					onChange = {onChange}
				/>
			</>}
		</div></Tab></Modal>
	</React.Fragment>))}
	</React.Fragment>))}</>)
}

export default Modals
