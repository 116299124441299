/** @jsx jsx */
import React, { useState } from "react"
import { jsx, Input, Select, Checkbox, Flex } from "theme-ui"

import { FaFont } from "./fa"
import useUpdate from "../hooks/use-update"

type FieldProps = {
	sxProp?: any
	label: string
	children: React.ReactNode
}

const Field = ({sxProp, label, children}: FieldProps) => (
	<label sx={{display: `block`, ...sxProp}}>
		<div sx={{
			ml: 2, fontSize: [1,1,2],
			height: [`21px`,`21px`,`24px`,],
		}}>{label}</div>
		{children}
	</label>
)

export default Field

type SetProps = {
	sxProp?: any
	id?: string
	t: any
	onChange: any
}

const FontSelector = ({sxProp, id, t, onChange}: SetProps) => (
	<Field sxProp={{width: `100%`, ...sxProp}}
		label={t(`Font`)}
	>
		<Select
			id={id ? id +`-font` : ``}
			sx={{px: [2,3], height: [`34px`,`34px`,`40px`],}}
			arrow={<div></div>}
			onChange={e => onChange(e.target.value)}
		>
			<option value="sans-serif">{t(`sans-serif`)}</option>
			<option value="851MkPOP">{t(`851MkPOP`)}</option>
			<option value="851Zatsu">{t(`851Zatsu`)}</option>
			<option value="851Kakutto">{t(`851Kakutto`)}</option>
			<option value="Hanazome Font">{t(`Hanazome Font`)}</option>
			<option value="Nyashi Friends">{t(`Nyashi Friends`)}</option>
			<option value="Tanugo">{t(`Tanugo`)}</option>
			<option value="K-FONT!">{t(`K-FONT!`)}</option>
			<option value="Best10 FONT">{t(`Best10 FONT`)}</option>
		</Select>
	</Field>
)

const TextSize = ({sxProp, id, t, onChange}: SetProps) => (
	<Field sxProp={{width: `40%`, ...sxProp}}
		label={<FaFont i="text-height" />}
	>
		<Input
			type="number" id={id ? id +`-size` : ``}
			sx={{px: [2,3], height: [`34px`,`34px`,`40px`],}}
			defaultValue={10}
			onChange={onChange}
		/>
	</Field>
)

const TextColor = ({sxProp, id, t, onChange}: SetProps) => (
	<Field sxProp={{width: `40%`, ...sxProp}}
		label={<FaFont i="paintbrush" />}
	>
		<Input
			type="color" id={id ? id +`-color` : ``}
			sx={{height: [`34px`,`34px`,`40px`],}}
			defaultValue="#ffffff"
			onChange={onChange}
		/>
	</Field>
)

const TextShadow = ({sxProp, id, t, onChange}: SetProps) => (
	<div sx={{width: `40%`, ...sxProp}}>
		<label sx={{
			display: `flex`, fontSize: [1,1,2],
			height: [`21px`,`21px`,`24px`,],
		}}>
			<Checkbox
				id={id ? id +`-check` : ``}
				defaultChecked={true}
				onChange={onChange}
			/>
			<div><FaFont i="brush" /></div>
		</label>
		<Input
			type="color" id={id ? id +`-shadow` : ``} 
			sx={{height: [`34px`,`34px`,`40px`],}}
			onChange={onChange}
		/>
	</div>
)

export const FieldSet = ({
	sxProp, id, t, onChange
}: SetProps) => (<Flex sx={{my: 3,}}>
	<FontSelector sxProp={sxProp} id={id} t={t} onChange={onChange} />
	<TextSize sxProp={{...sxProp, ml: 2,}} id={id} t={t} onChange={onChange} />
	<TextColor sxProp={{...sxProp, ml: 2,}} id={id} t={t} onChange={onChange} />
	<TextShadow sxProp={{...sxProp, ml: 2,}} id={id} t={t} onChange={onChange} />
</Flex>)

type FieldSwitchProps = {
	SetProps
	type: string
	defaultChecked?: boolean
}

const FieldSwitch = ({
	sxProp, id, t, type, onChange, defaultChecked
}: FieldSwitchProps) => {
	const [check, setCheck] = useState(defaultChecked)
	useUpdate(()=>{
		onChange()
	},[check])
	return (<div sx={{
		background: (check ? `accent` : `bga`),
		border: `solid 1px`,
		borderColor: `border`,
		borderRadius: `.3em`,
		...sxProp
	}}>
		<label>
			<input
				onChange = {e => setCheck(e.target.checked)}
				type = "checkbox"
				id = {id +`-checkbox-`+ type}
				sx = {{ display: `none`, }}
				defaultChecked = {defaultChecked}
			/>
			<div sx={{
				textAlign: `center`,
				py: 2,
			}}>
				{check == true ? t(`ON`) : t(`OFF`)}
			</div>
		</label>
		{type == `font` ?
			<FontSelector id={id} t={t} sxProp={{width: `100%`,}} onChange={onChange} />
		: type == `size` ?
			<TextSize id={id} t={t} sxProp={{width: `100%`,}} onChange={onChange} />
		: type == `color` ?
			<TextColor id={id} t={t} sxProp={{width: `100%`,}} onChange={onChange} />
		: type == `shadow` ?
			<TextShadow id={id} t={t} sxProp={{width: `100%`,}} onChange={onChange} />
		: null}
	</div>)
}

type FieldSwitchSetProps = {
	SetProps
	defaultChecked?: string
}

export const FieldSwitchSet = ({
	sxProp, id, t, onChange, defaultChecked
}: FieldSwitchSetProps) => (<Flex>
	<FieldSwitch sxProp={{width: `100%`, ...sxProp}}
		id={id} t={t} type="font" onChange={onChange}
		defaultChecked={defaultChecked == `font` ? true : false} />
	<FieldSwitch sxProp={{width: `40%`, ml: 2, ...sxProp}}
		id={id} t={t} type="size" onChange={onChange}
		defaultChecked={defaultChecked == `size` ? true : false} />
	<FieldSwitch sxProp={{width: `40%`, ml: 2, ...sxProp}}
		id={id} t={t} type="color" onChange={onChange}
		defaultChecked={defaultChecked == `color` ? true : false} />
	<FieldSwitch sxProp={{width: `40%`, ml: 2, ...sxProp}}
		id={id} t={t} type="shadow" onChange={onChange}
		defaultChecked={defaultChecked == `shadow` ? true : false} />
</Flex>)

type RadioLabelsProps = {
	sxProp?: any
	name: string
	labels: string[]
	values: string[]
	defaultChecked: string
	onChange: any
}

const RadioLabels = ({
	sxProp, name, labels, values, defaultChecked, onChange
}: RadioLabelsProps) => {
	const [val,setVal] = useState(defaultChecked)
	useUpdate(()=>{
		onChange()
	},[val])
	return (<Flex sx={sxProp}>
		{labels.map((label,i)=>(<React.Fragment key={i}>
			<label sx={{
				background: (val == values[i] ? `accent` : `bga`),
				border: `solid 1px`,
				borderColor: `border`,
				borderRadius: `.3em`,
				width: `100%`,
				ml: i==0 ? 0 : 2,
			}}>
				<input
					onChange = {e => setVal(e.target.value)}
					type="radio" name={name} value={values[i]}
					defaultChecked={defaultChecked == values[i]}
					sx={{display: `none`,}}
				/>
				<div sx={{textAlign: `center`, my: 1,}}>{label}</div>
			</label>
		</React.Fragment>))}
	</Flex>)
}

export const TextAlign = ({sxProp, id, t, onChange}: SetProps) => (
	<RadioLabels
		sxProp = {sxProp}
		name = {id +`-align`}
		labels = {[t(`Left`), t(`Center`), t(`Right`)]}
		values = {[`left`, `center`, `right`]}
		defaultChecked = "center"
		onChange = {onChange}
	/>
)

type CheckboxFieldProps = {
	SetProps
	label: string
}

export const CheckboxField = ({
	sxProp, id, t, onChange, label
}: CheckboxFieldProps) => (
	<label sx={{display: `flex`, ...sxProp}}>
		<Checkbox id={id} onChange={onChange} />
		{label}
	</label>
)

export const CheckText = ({sxProp, id, t, onChange}: SetProps) => (
	<RadioLabels
		sxProp = {sxProp}
		name = {id +`-text`}
		labels = {[`✔`,`❤`,`❄`,`★`,`👉`]}
		values = {[`✔`,`❤`,`❄`,`★`,`👉`]}
		defaultChecked = "✔"
		onChange = {onChange}
	/>
)

export const FrameText = ({sxProp, id, t, onChange}: SetProps) => (
	<RadioLabels
		sxProp = {sxProp}
		name = {id +`-text`}
		labels = {[`◯`,`♡`,`◇`,`□`]}
		values = {[`◯`,`♡`,`◇`,`□`]}
		defaultChecked = "◯"
		onChange = {onChange}
	/>
)
