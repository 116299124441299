/** @jsx jsx */
import React, { useEffect, useState, useRef } from "react"
import { jsx, Flex, Input, Select } from "theme-ui"

import Tab from "./tab"
import { modalOpen } from "./modal"
import Characters from "./characters"
import { UpTplNaga } from "./upload-image"
import Avatar from "./avatar"
import Modals from "./@player-card-modals"
import { FieldSet } from "./@player-card-field-set"
import { usePlayerCardTool } from "../hooks/use-player-card"
import useUpdate from "../hooks/use-update"

type CanvasProps = {
	cardId: string
	t: any
}

const Canvas = ({cardId, t}: CanvasProps) => {
	const cards = usePlayerCardTool()
		, card = cards.find(e => e.id == cardId) ? cards.find(e => e.id == cardId) : cards[0]
		, dom = useRef()
		, dlDom = useRef()
		, textDom = useRef()
		, cMC = useRef(new MyCanvas())
		, storage = new LocalStorage(`player-card`)
		, fetchTemplate = (url) => {
			cMC.current.loadTpl(url)
		}
		, fetchPng = (name) => {
			fetchTemplate(`/images/`+ cardId +`/`+ name + `.png`)
		}
		, fetchJpg = (name) => {
			fetchTemplate(`/images/`+ cardId +`/`+ name + `.jpg`)
		}
		, draw = (e, id) => {
			if(id) {
				cMC.current.saveImage(e, id)
			} else {
				cMC.current.draw(typeof e == "string" ? e : ``)
			}
		}
	cMC.current.id = cardId	//要素更新時に関数が動作する前にID取得
	useUpdate(()=>{
		cMC.current.init(dom.current, dlDom.current, textDom.current)
		//cMC.current.loadStorage(storage.g(cardId))
	},[cardId])
	useEffect(()=>{
		return ()=>{
			//storage.s(cardId, cMC.current.storage)
		}
	},[cardId])
	return (<>
		<Modals onChange={draw} t={t} />
		<div ref={textDom} sx={{
			textAlign: `center`,
			overflow: `hidden`,
			transition: `height 0.2s ease-out`,
		}}>{t(`Click on a template to edit it.`)}</div>
		<canvas
			ref={dom} onClick={e => cMC.current.click(e)}
			width={card.canvas.w} height={card.canvas.h}
			sx={{display: `block`, mx: `auto`,}}
		/>
		<div ref={dlDom} sx={{
			textAlign: `center`,
			my: [`24px`,4],
			"> .dl-link": {
				color: `text`,
				px: 3,
				border: `solid 1px`,
				borderColor: `second`,
				borderRadius: `.5em`,
			},
		}}>...</div>
		<Tab
			name="mode"
			labels={[t(`Editor`),t(`Stamp`)]}
			values={[`editor`,`stamp`]}
		>
			<div><Flex sx={{
				flexDirection: [`column`,`column`,`row`],
				">*": { width: `100%`, },
			}}>
				{cardId == "fukafukafuka29" ?
					<Characters t={t} onChange={fetchPng} name={cardId + `-character`} listName={cardId} />
				: cardId == "naga-ken" ?
					<UpTplNaga t={t} onChange={fetchTemplate} link="//naga-ken.info/genshin-self-introduction-card/" />
				: cardId == "ge_mpie" ?
					<div>元気なときに実装します．．．
					<br/>（iOS以外は，以前のツールで一応作れます）</div>
				: cardId == "genshin_wazooo" ?
					<div>{fetchTemplate(`/images/genshin_wazooo/Enemies.jpg`)}</div>
				: cardId == "genshin_wazooo-Hilichurl" ?
					<div>{fetchTemplate(`/images/genshin_wazooo/Hilichurl.jpg`)}</div>
				: cardId == "catudon_1276" ?
					<CatudonSelector list={card.custom.list} t={t} onChange={fetchPng} />
				: cardId == "genshin_wazooo-vertical" ?
					<div>{fetchTemplate(`/images/genshin_wazooo-vertical/Enemies.jpg`)}</div>
				: cardId == "naga-ken-vertical" ?
					<UpTplNaga t={t} onChange={fetchTemplate} link="//naga-ken.info/genshin-self-introduction-card_ver3/" />
				: cardId == "genshinlev-vertical" ?
					<LevSelector list={card.custom.list} t={t} onChange={fetchJpg} />
				: null}
				<FieldSet id="common" t={t} onChange={draw} />
			</Flex></div>
			<div>
				スタンプ機能は開発中
			</div>
		</Tab>
	</>)
}

export default Canvas

type SelectorProps = {
	list: string[]
	t: any
	onChange: any
}

const CatudonSelector = ({list, t, onChange}: SelectorProps) => {
	useEffect(()=>{
		onChange(list[0])
	},[])
	return (<div>
		<div>{t(`Change Theme`)}</div>
		<Flex>
			{list.map((e,i)=>(<React.Fragment key={i}><label>
				<input onChange={e => onChange(e.target.value)}
					type="radio"
					name="catudon_1276-selector"
					value={e}
					defaultChecked={i==0 ? true : false}
					sx={{
						display: `none`,
						"&:checked + div": {
							borderColor: `yellow`,
						},
					}}
				/>
				<div sx={{
					border: `solid 3px`,
					borderColor: `border`,
					borderRadius: `50%`,
					overflow: `hidden`,
					m: 1,
					width: [`48px`,`48px`,`64px`],
					height: [`48px`,`48px`,`64px`],
				}}>
					<Avatar folder="nation" name={e} />
				</div>
			</label></React.Fragment>))}
		</Flex>
	</div>)
}

const LevSelector = ({list, t, onChange}: SelectorProps) => {
	useEffect(()=>{
		onChange(list[0])
	},[])
	return (<div>
		<div>{t(`Change Base Color`)}</div>
		<Flex>
			{list.map((e,i)=>(<React.Fragment key={i}><label>
				<input onChange={e => onChange(e.target.value)}
					type="radio"
					name="genshinlev-vertical-selector"
					value={e}
					defaultChecked={i==0 ? true : false}
					sx={{
						display: `none`,
						"&:checked + div": {
							borderColor: `yellow`,
						},
					}}
				/>
				<div sx={{
					border: `solid 3px`,
					borderColor: `border`,
					borderRadius: `50%`,
					overflow: `hidden`,
					m: 1,
					background: e,
					width: [`48px`,`48px`,`64px`],
					height: [`48px`,`48px`,`64px`],
				}} />
			</label></React.Fragment>))}
		</Flex>
	</div>)
}

class MyCanvas {	//canvasの管理
	constructor() {
		this._cards = usePlayerCardTool()
		this._card = this._cards[0]
		this._dom = ``
		this._dlDom = ``
		this._textDom = ``
		this._ctx = ``
		this._id = ``
		this._cache = ``
		this._storage = ``
		this._tplUrl = []
		this._tplImg = typeof window !== `undefined` ? new Image() : null
		this._font = `sans-serif`
		this._checkShadow = false
		this._fontLoadFlag = {"sans-serif": true}
		this._blobFlag = true
	}
	set id(id) {
		this._id = id
		this._card = this._cards.find(e => e.id == this._id)
	}
	get tplUrl() {
		return this._tplUrl[this._id]
	}
	get storage() {
		return this._storage
	}
	init(dom, dlDom, textDom) {
		this._dom = dom
		this._ctx = dom.getContext(`2d`)
		this._dlDom = dlDom
		this._textDom = textDom
		this.loadTpl(null)
	}
	loadStorage(data) {
		console.log(data)
	}
	loadTpl(url) {
		if(url) this._tplUrl[this._id] = url
		if(this._ctx && this._tplUrl[this._id]) {
			this._dlDom.innerHTML = `... Loading`	//場所違うかも
			this._tplImg.onload = ()=>{ this.draw(); }
			this._tplImg.src = this._tplUrl[this._id]
			this._ctx.textBaseline = `middle`
		}
	}
	saveImage(url, id) {
		this._cache[id] = new Image()
		this._cache[id].onload = ()=>{ this._draw(); }
		this._cache[id].onerror = ()=>{ this._draw(); }
		this._cache[id].src = url
	}
	_img(image, area, offset) {	//area = [左x, 上y, 右x, 下y]
		this._ctx.shadowBlur = 0
		if(area) {
			const drawArea = [
					area[0] + (offset ? offset[0] : 0),
					area[1] + (offset ? offset[1] : 0),
					area[2] - area[0],
					area[3] - area[1],
				]
				, wph = {
					d: drawArea[2] / drawArea[3],
					i: image.width / image.height,
				}
				, isHi = wph.d > wph.i ? true : false
				, imgArea = [
					isHi ? 0 : image.width / 2 * (1 - wph.d / wph.i),
					isHi ? image.height / 2 * (1 - wph.i / wph.d) : 0,
					isHi ? image.width : image.height * wph.d,
					isHi ? image.width / wph.d : image.height,
				]
			this._ctx.drawImage(image, imgArea[0], imgArea[1], imgArea[2], imgArea[3], drawArea[0], drawArea[1], drawArea[2], drawArea[3])
		} else {
			this._ctx.drawImage(image ? image : this._tplImg, 0, 0)
		}
	}
	_save() {
//		let data = {...this._cache, ...fetchFont(`common`)}
		let data = fetchFont(`common`)
		this._card.modal.forEach((e,i)=>{
			if(e.settingType == "image") return	//saveImageでやってる
			const name = this._card.id +`-`+ i
			e.field.forEach((e2,j)=>{
				if(e2.type == "checkbox" || e2.type == "frame") {
					e2.items.forEach((e3,k)=>{
						e3.forEach((e4,l)=>{
							const idName = name +`-`+j +`-`+k +`-`+l
								, elem = document.getElementById(idName)
							data[idName] = elem.checked
						})
					})
					return
				}
				const idName = name +`-`+ j
					, elem = document.getElementById(idName)
				data[idName] = elem.value
			})
			if(e.settingType=="checkbox" || e.settingType=="frame") {
				data[name +`-text`] = radioValue(name +`-text`)
			} else {
				data[name +`-align`] = radioValue(name +`-align`)
			}
			data = {...data, ...fetchFont(name)}
		})
//		this._cache = data
		this._cache = {...this._cache, ...data}
		this._storage = data
	}
	_offset() {
		const c = this._card.custom
		if(c && c.type == "offset") {
			if(c.refType == "radio") {
				const val = radioValue(this._id +`-`+ c.ref)
				return c.list.some(e => e == val) ? c.values : [0,0]
			}
		}
		return [0,0]
	}
	_fontSize(size) {
		this._ctx.font = size +`px "`+ this._font +`"`
		this._ctx.shadowBlur = this._checkShadow ? (size / 8) : 0
	}
	_fillText(text, x, y) {
		for(let i=0; i<10; i++) {
			this._ctx.fillText(text, x, y)
			if(!this._checkShadow) break
		}
	}
	_blob() {
		if(this._blobFlag) {
			this._dlDom.innerHTML = `... Waiting`
			this._blobFlag = false
			setTimeout(()=>{
				this._dom.toBlob(blob=>{
					const url = URL.createObjectURL(blob)
						, dl = document.createElement(`a`)
						, tab = document.createElement(`a`)
					dl.href = url
					tab.href = url
					dl.download = `player-card.jpg`
					tab.target = `_blank`
					dl.innerHTML = `Download`
					tab.innerHTML = `New Tab`
					dl.classList.add(`dl-link`)
					this._dlDom.innerHTML = dl.outerHTML +`&emsp;or&emsp;`+ tab.outerHTML
				}, "image/jpeg", 0.99)
				this._blobFlag = true
			},500)
		}
	}
	_draw() {
		//オフセット取得
		const offset = this._offset()
			, c = this._cache
		//背景画像描写
		this._card.modal.forEach((e,i)=>{
			e.field.forEach((e2,j)=>{
				if (e2.preDraw) {
					const img = c[this._card.id +`-`+ i +`-`+ j]
					if(img) this._img(img, e2.area, offset)
				}
			})
		})
		//テンプレート描写
		if(this._id == "naga-ken-vertical") {
			this._img(this._tplImg, [0,424, 1000,1411])
		} else {
			this._img()
		}
		//入力内容描写
		this._card.modal.forEach((e,i)=>{
			const name = this._card.id +`-`+ i
			let fontSize = 0
			if(e.settingType == "image") {
				
			} else {
				//font setting
				this._ctx.textAlign = c[name +`-align`] ?
					c[name +`-align`] : `center`
				this._font = c[name +`-checkbox-font`] ?
					c[name +`-font`] : c[`common-font`]
				this._checkShadow = c[name +`-checkbox-shadow`] ?
					c[name +`-check`] : c[`common-check`]
				this._ctx.fillStyle = c[name +`-checkbox-color`] ?
					c[name +`-color`] : c[`common-color`]
				this._ctx.shadowColor = c[name +`-checkbox-shadow`] ?
					c[name +`-shadow`] : c[`common-shadow`]
				if(e.baseSize) {
					fontSize = e.baseSize * (c[name +`-checkbox-size`] ? c[name +`-size`] : c[`common-size`])
					this._fontSize(fontSize)
				}
			}
			//text writing
			e.field.forEach((e2,j)=>{
				if(e2.type == "checkbox" || e2.type == "frame") {
					e2.items.forEach((e3,k)=>{
						e3.forEach((e4,l)=>{
							if(c[name +`-`+ j +`-`+ k +`-`+ l])
								this._fillText(
									c[name +`-text`],
									e4.pos[0] + offset[0],
									e4.pos[1] + offset[1]
								)
						})
					})
					return	//以下をスキップ
				} else if (e2.type == "image") {
					const img = c[name +`-`+ j]
					if(img && !e2.preDraw)
						this._img(img, e2.area, offset)
					return	//以下をスキップ
				}
				const idName = name +`-`+ j
					, cY = (e2.area[1] + e2.area[3]) / 2 + offset[1]
				let x = c[name +`-align`] == "left" ? e2.area[0]
						: c[name +`-align`] == "right" ? e2.area[2]
						: (e2.area[0] + e2.area[2]) / 2
				x += offset[0]
				//画面外はスキップ
				if( x < 0 || cY < 0 ) return
				if(e2.type == "textarea") {
					const text = textToAry(c[idName])
						, len = text.length
						, rY = (len < e2.row.length) ? e2.row[len - 1]
							: e2.row[e2.row.length - 1]
						, size = c[name +`-checkbox-size`]
							? c[name +`-size`] : c[`common-size`]
					this._fontSize( (rY / len) * (size / 10) * 0.7 )
					text.forEach((e3,k)=>{
						this._fillText(e3, x, cY - rY/2 + rY/len*(k + 0.5))
					})
				} else {
					this._fontSize(fontSize)
					this._fillText(c[idName], x, cY)
				}
			})
		})
		this._blob()
	}
	_fontLoad(font) {
		if(!this._fontLoadFlag[font]) {
			document.fonts.load(`1em "`+ font +`"`).then(()=>{
				this._draw()
			})
			this._fontLoadFlag[font] = true
		}
	}
	draw(str) {
		if(str == "draw"){
			this._draw()
		}
		else {
			this._save()
			this._draw()
			if(str) this._fontLoad(str)
		}
	}
	_hiddenText() {
		const e = this._textDom
		if(e.style.height != `0px`) {
			e.style.height = e.scrollHeight +`px`
			setTimeout(()=>{ e.style.height = `0px` }, 1)
		}
	}
	click(e) {
		if(this._tplUrl[this._id]) {
			const val = "editor"//radioValue(`tab-mode`)
				, rect = e.target.getBoundingClientRect()
				, pX = Math.round( (e.clientX - rect.left) * this._dom.width / this._dom.clientWidth )
				, pY = Math.round( (e.clientY - rect.top) * this._dom.height / this._dom.clientHeight )
				, modal = this._card.modal
				, offset = this._offset()
			if(val == "editor") {
				for(let i=0; i<modal.length; i++) {
					for(let j=0; j*4<modal[i].clickArea.length; j++) {
						const x = modal[i].clickArea[0 + j*4]
							, y = modal[i].clickArea[1 + j*4]
							, w = modal[i].clickArea[2 + j*4] - x
							, h = modal[i].clickArea[3 + j*4] - y
						//画面外はスキップ
						if( x+offset[0] < 0 || y+offset[0] < 0 )
							continue
						this._ctx.beginPath()
						this._ctx.rect(x+offset[0], y+offset[1], w, h)
						if( this._ctx.isPointInPath(pX, pY) ) {
							modalOpen(this._card.id +`-`+ i)
							this._hiddenText()
							break;
						}
					}
				}
			} else if(val == "stamp") {
				console.log(`stamp`)
			}
		}
	}
}

class LocalStorage {	//ローカルストレージ
	constructor(key) {
		this._key = key
	}
	g(sub) {
		const data = JSON.parse( localStorage.getItem( this._key ) )
		if(sub) return data[sub]
		else return data
	}
	s(sub, subArr) {
		const old = this.g()
		let next = []
		if(old == null) {
			next[sub] = subArr
		} else {
			next = {...old}
			next[sub] = { ...old[sub], ...subArr }
		}
		localStorage.setItem( this._key, JSON.stringify(next) )
	}
}

//-----ラジオボタンのvalue読み取り-----
const radioValue = (name) => {
	let val = ``
	document.getElementsByName(name).forEach(e => {
		if(e.checked) val = e.value
	})
	return val
}

//-----フォント情報を読み取って返却-----
const fontKey = ["font", "size", "color", "check", "shadow"] //他でも使う
const fetchFont = (pre) => {
	let data = {}
	if(pre != "common") {
		fontKey.forEach(e=>{
			const id = pre +`-checkbox-`+ e
			if(e != "check") data[id] = document.getElementById(id).checked
		})
	}
	fontKey.forEach(e=>{
		const id = pre +`-`+ e
		if(e == "check") data[id] = document.getElementById(id).checked
		else data[id] = document.getElementById(id).value
	})
	return data
}

//-----テキストエリアを改行毎の配列に-----
function textToAry(text) {
	//入力文字を1文字毎に配列化
	const aryText = text.split('')
	//出力用の配列を用意
	var aryRow = [],
		row_cnt = 0
	aryRow[0] = ''
	
	//入力1文字毎にループ　改行コードもしくは折り返しで配列の添え字を足す
	for(const e of aryText) {
		if(e == "\n"){
			row_cnt++
			aryRow[row_cnt] = ''
		} else {
			aryRow[row_cnt] += e
		}
	}
	return aryRow
}
