/** @jsx jsx */
import { useState } from "react"
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Details from "../components/details"
import ExternalLink, { ExternalLinks } from "../components/external-link"
import Selector from "../components/@player-card-selector"
import Author from "../components/@player-card-author"
import Canvas from "../components/@player-card-canvas"

const Page = () => {
	const {t} = useTranslation()
		, [cardId,setCardId] = useState()
	
	return (<Layout title={t(`Player Card Generator`)}>
		<Selector label={t(`Change Template`)} set={setCardId} />
		<Author cardId={cardId} t={t} />
		<Canvas cardId={cardId} t={t} />
		
		<Details label={t(`Credit`)} sxProp={{mt: [`48px`,`48px`,5]}}>
			<Columns csx={{mb:0}} mb={0}><div>
				<h3>{t(`Templates`)}</h3>
				<ExternalLinks links={[
					{ label: `@fukafukafuka29`, href: `//twitter.com/fukafukafuka29`, },
					{ label: `@Luke_nagaken`, href: `//twitter.com/Luke_nagaken`, },
					{ label: `@ge_mpie`, href: `//twitter.com/ge_mpie`, },
					{ label: `@genshin_wazooo`, href: `//twitter.com/genshin_wazooo`, },
					{ label: `@catudon_1276`, href: `//twitter.com/catudon_1276`, },
					{ label: `@genshinlev`, href: `//twitter.com/genshinlev`, },
				]} />
			</div><div>
				<h3>{t(`Fonts`)}</h3>
				<ExternalLinks links={[
					{ label: t(`851 Series`), href: `//pm85122.onamae.jp/85122font.html`, },
					{ label: t(`Hanazome Font`), href: `//www.asterism-m.com/font/hanazomefont/`, },
					{ label: t(`Nyashi Friends`), href: `//marusexijaxs.web.fc2.com/tegakifont.html#tegakifont5`, },
					{ label: t(`Tanugo`), href: `//tanukifont.com/tanugo/`, },
					{ label: t(`K-FONT!`), href: `http://font.sumomo.ne.jp/font_1.html`, },
					{ label: t(`Best10 FONT`), href: `//booth.pm/ja/items/2747965`, },
				]} />
			</div></Columns>
		</Details>
		<div sx={{mt: [`48px`,`48px`,5],}}>
			<p>以前の作成ツール↓
			<br/><ExternalLink href={`https://luislab.net/player-card-generator/`} />
			<br/>不具合や何か理由がある時使用してください．</p>
		</div>
	</Layout>)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["player-card", "characters"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
