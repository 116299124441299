/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react"
import { jsx } from "theme-ui"

//id -> modal-{id}
type ModalProps = {
	label?: React.ReactNode
	sxLabel?: any
	sxContent?: any
	children: React.ReactNode
	id?: string
	safety?: boolean
}

const Modal = ({
	label, sxLabel, sxContent, children, id, safety=true
}: ModalProps) => {
	const modal = useRef()
		, [opened, setOpened] = useState(false)
		, open = () => {
			if(!safety) setOpened(true)
			pushHistory()
			modal.current.style.transition = `opacity .2s ease-in-out`
			modal.current.style.height = `100vh`
			setTimeout(()=>{
				modal.current.style.opacity = 1
			},1)
		}
		, close = () => {
			modal.current.style.transition = `opacity .1s ease-in-out`
			modal.current.style.opacity = 0
			setTimeout(()=>{
				modal.current.style.height = 0
			},100)
		}
	useEffect(()=>{
		const modalBack = () => {
			if(modal.current.style.height != `0px`) {
				close()
			}
		}
		window.addEventListener('popstate', modalBack)
		return () => {
			window.removeEventListener('popstate', modalBack)
		}
	},[])
	return (<>
		{label && (<div onClick={open} sx={{
			cursor: `pointer`,
			...sxLabel
		}}>{label}</div>)}
		<div
			ref={modal}
			id={id ? (`modal-`+ id) : ``}
			sx={{
				opacity: 0,
				position: `fixed`,
				top: 0, left: 0,
				width: `100vw`,
				height: `0`,
				overflow: `hidden`,
				zIndex: 10,
			}}
			style={{height: 0,}}
		>
			<div onClick={modalClose} sx={{
				width: `100%`,
				height: `100%`,
				background: `bga`,
			}} />
			<div sx={{
				position: `absolute`,
				overflowY: `auto`,
				top: `50%`, left: `50%`,
				transform: `translate(-50%,-50%)`,
				fontSize: [2,2,3],
				background: `bg2`,
				border: `solid 1px`,
				borderColor: `border`,
				borderRadius: `.5em`,
				p: 3,
				maxHeight: `80vh`,
				...sxContent
			}}>
				{(safety || opened) ? children : null}
			</div>
		</div>
	</>)
}

export default Modal

const pushHistory = () => {
	history.pushState(`modal`, null, `#modal`)
}

//safety=false には NG
export const modalOpen = (id: string) => {
	const e = document.getElementById(`modal-`+ id)
	pushHistory()
	e.style.transition = `opacity 0.2s ease-in-out`
	e.style.height = `100vh`
	setTimeout(()=>{
		e.style.opacity = 1
	},1)
}

export const modalClose = () => {
	if(history.state == `modal`) history.back()
}
