/** @jsx jsx */
import React, { useEffect, useRef } from "react"
import { jsx, Flex } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Img from "./image"
import Details, { closeDetails } from "./details"
import { usePlayerCardTool } from "../hooks/use-player-card"

type SelectorProps = {
	label: string
	set: any
}

const Selector = ({label, set}: SelectorProps) => {
	const arr = usePlayerCardTool()
/*	
	const e = useRef()
		, click = () => {
			if(e.current.clientHeight == e.current.scrollHeight)
				e.current.style.height = "0px"
			else
				e.current.style.height = e.current.scrollHeight +`px`
		}*/
	useEffect(()=>{
		//セレクタをURLクエリで初期化
		var num = 0
		const q = new URLSearchParams(window.location.search)
			, elements = document.getElementsByName(`template-selector`)
		arr.forEach((card,i)=>{
			if( q.get(`t`) == card.id ) num = i
		})
		elements[num].checked = true
		set(arr[num].id)
		
		//リサイズへの対応
/*		const resize = () => {
			if(e.current.style.height != "0px") {
				e.current.style.height = "auto"
				e.current.style.height = e.current.scrollHeight +`px`
			}
		}
		window.addEventListener('resize', resize)
		return () => {
			window.removeEventListener('resize', resize)
		}*/
	},[])
	
	return (<Details label={label}>
		<Flex>
			<div sx={{ width: `100%`, }}>
				{arr.map((card,i)=>(<React.Fragment key={i}>
					{!card.vertical &&(<label>
						<input onChange={e => set(e.target.value)}
							type="radio"
							name="template-selector"
							value={card.id}
							sx={{
								display: `none`,
								"&:checked + div": {
									borderColor: `yellow`,
								},
							}}
						/>
						<div onClick={closeDetails} sx={{
							border: `solid 3px`,
							borderColor: `border`,
							borderRadius: `16px`,
							overflow: `hidden`,
							my: [3,4],
						}}>
							<Img folder="player-card-sample" name={card.id} />
						</div>
					</label>)}
				</React.Fragment>))}
			</div>
			<div sx={{ width: `66%`, ml: [3,4], }}>
				{arr.map((card,i)=>(<React.Fragment key={i}>
					{card.vertical &&(<label>
						<input onChange={e => set(e.target.value)}
							type="radio"
							name="template-selector"
							value={card.id}
							sx={{
								display: `none`,
								"&:checked + div": {
									borderColor: `yellow`,
								},
							}}
						/>
						<div onClick={closeDetails} sx={{
							border: `solid 3px`,
							borderColor: `border`,
							borderRadius: `16px`,
							overflow: `hidden`,
							my: [3,4],
						}}>
							<Img folder="player-card-sample" name={card.id} />
						</div>
					</label>)}
				</React.Fragment>))}
			</div>
		</Flex>
	</Details>)
}

export default Selector


	/*<>
		<div onClick={click} sx={{
			textAlign: `center`,
			fontSize: [2,2,3],
			py: 1,
			background: `bga`,
			border: `solid 1px`,
			borderColor: `border`,
			borderRadius: `.5em`,
			cursor: `pointer`,
		}}>{label}</div>
		<div ref={e} sx={{
			overflow: `hidden`,
			transition: `height 0.2s ease-out`,
		}} style={{
			height: "0px",
		}}><Flex>
			<div sx={{ width: `100%`, }}>
				{arr.map((card,i)=>(<React.Fragment key={i}>
					{!card.vertical &&(<label>
						<input onChange={e => set(e.target.value)}
							type="radio"
							name="template-selector"
							value={card.id}
							sx={{
								display: `none`,
								"&:checked + div": {
									borderColor: `yellow`,
								},
							}}
						/>
						<div onClick={click} sx={{
							border: `solid 3px`,
							borderColor: `border`,
							borderRadius: `16px`,
							overflow: `hidden`,
							my: [3,4],
						}}>
							<Img folder="player-card-sample" name={card.id} />
						</div>
					</label>)}
				</React.Fragment>))}
			</div>
			<div sx={{ width: `66%`, ml: [3,4], }}>
				{arr.map((card,i)=>(<React.Fragment key={i}>
					{card.vertical &&(<label>
						<input onChange={e => set(e.target.value)}
							type="radio"
							name="template-selector"
							value={card.id}
							sx={{
								display: `none`,
								"&:checked + div": {
									borderColor: `yellow`,
								},
							}}
						/>
						<div onClick={click} sx={{
							border: `solid 3px`,
							borderColor: `border`,
							borderRadius: `16px`,
							overflow: `hidden`,
							my: [3,4],
						}}>
							<Img folder="player-card-sample" name={card.id} />
						</div>
					</label>)}
				</React.Fragment>))}
			</div>
		</Flex></div>
	</>)*/

